<template>
  <div class="flex justify-between font-bold flex-0 pointer-events-none">
    <div class="md:w-[90px] text-center">
      <div class="text-[30px] md:text-[62px] scale-y-75 text-red-blood leading-none -mb-3 md:-mb-6 tabular-nums">
        {{ pad(days) }}
      </div>
      <div class="text-4xs md:text-xs uppercase">
        Days
      </div>
    </div>
    <div class="md:pt-7 text-[24px]">:</div>
    <div class="md:w-[90px] text-center">
      <div class="text-[30px] md:text-[62px] scale-y-75 text-red-blood leading-none -mb-3 md:-mb-6 tabular-nums">
        {{ pad(hours) }}
      </div>
      <div class="text-4xs md:text-xs uppercase">
        Hours
      </div>
    </div>
    <div class="md:pt-7 text-[24px]">:</div>
    <div class="md:w-[90px] text-center">
      <div class="text-[30px] md:text-[62px] scale-y-75 text-red-blood leading-none -mb-3 md:-mb-6 tabular-nums">
        {{ pad(minutes) }}
      </div>
      <div class="text-4xs md:text-xs uppercase">
        Minutes
      </div>
    </div>
    <div class="md:pt-7 text-[24px]">:</div>
    <div class="md:w-[90px] text-center">
      <div class="text-[30px] md:text-[62px] scale-y-75 text-red-blood leading-none -mb-3 md:-mb-6 tabular-nums">
        {{ pad(seconds) }}
      </div>
      <div class="text-4xs md:text-xs uppercase">
        Seconds
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { pad } from '@/utils/pad';

const props = defineProps({
  closeTime: {
    type: String,
    default: null,
  },
  isClosed: {
    type: Boolean,
    default: false,
  },
});

const { $dayjs, } = useNuxtApp();

const uiStore = useUiStore();
const {
  secondsTicker,
} = storeToRefs(uiStore);

const days = ref(0);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);

function update() {
  const now = $dayjs();
  const endDate = $dayjs(props.closeTime);
  const secondsLocal = endDate.diff(now, 's');

  if (!props.isClosed && secondsLocal > 0) {
    days.value = Math.floor(secondsLocal / (3600 * 24));
    hours.value = Math.floor(secondsLocal % (3600 * 24) / 3600);
    minutes.value = Math.floor(secondsLocal % 3600 / 60);
    seconds.value = Math.floor(secondsLocal % 60);
  } else {
    days.value = '00';
    hours.value = '00';
    minutes.value = '00';
    seconds.value = '00';
  }
}

watch(
  () => secondsTicker.value,
  () => {
    update();
  }
);

onMounted(() => {
  update();
});
</script>
