<template>
  <article :class="[cardSize ? cardSize : 'card-medium']">
    <component
      :is="wrapper"
      :to="landingLink"
      @click.stop="cardAction"
    >
      <div
        class="flex flex-col rounded-lg transition-transform duration-200 lg:hover:scale-[1.03] relative bg-slate-900 cursor-pointer group overflow-hidden"
      >
        <div v-if="locationRestricted" class="absolute z-20 group -top-[4px] -bottom-[4px] -left-[4px] -right-[4px] flex justify-center items-center bg-black/75 opacity-0 group-hover:opacity-100 transition-opacity">
          <div class="text-xs opacity-0 top-10 group-hover:opacity-100 -mt-4 group-hover:mt-0 transition-margin duration-200 text-center mx-3 font-bold">
            This game is not available in your current location.
          </div>
        </div>

        <!-- Top right corner badges -->
        <div class="absolute z-10 top-1 right-1 flex flex-col items-end">
          <CardLiveCasinoBadgeOpen v-if="id !== 'more' && dynamicData" :table-open="dynamicData?.tableOpen"/>

          <CardGameTag
            v-for="(tag, index) in tagsDisplay"
            :key="index"
            :label="tag"
          />
        </div>

        <figure class="w-full aspect-[223/300] overflow-hidden relative z-0 -top-[0.5px] animate-fade-in">
          <ImageLoader
            :classes="['object-cover h-full w-full block relative z-10']"
            card-dark
            :image-url="$imgSrcResize(imageUrl, 223, 300)"
            :alt="gameName"
            :width="223"
            :height="300"
            loading-type="lazy"
          />

          <div
            v-if="id === 'more'"
            class="absolute w-full h-full z-20 top-0 left-0 flex items-center justify-center bg-black/10 transition-all group-hover:bg-black/0 px-1"
          >
            <ButtonButton theme="grey" size="xs" class="drop-shadow-[0_0px_30px_rgba(255,255,255,0.5)] font-bold text-xs leading-none">
              {{ game.name }}
            </ButtonButton>
          </div>

          <CardRaffleLabelMore v-else-if="!locationRestricted" :color-theme="colorTheme">
            Play now
          </CardRaffleLabelMore>
        </figure>
      </div>
    </component>
    <CardGameIndicators
      v-if="showRtp && id !== 'more'"
      :live-rtp="game.liveRTP"
      :rtp="game.rtp"
      :show-live-rtp="showLiveRtp"
      :show-volatility="showVolatility"
      :volatility="game.volatility"
    />
  </article>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';
import { useLocationStore } from '@/store/location';
import { useUserAuth } from '@/composables/useUserAuth';

const props = defineProps({
  colorTheme: {
    type: String,
    default: null,
  },
  game: {
    type: Object,
    default: null,
  },
  tags: {
    type: Array,
    default: null,
  },
  id: {
    type: String,
    default: null,
  },
  category: {
    type: String,
    default: null,
  },
  dynamicData: {
    type: Object,
    default: null,
  },
  cardSize: {
    type: String,
    default: null,
  },
  showRtp: {
    type: Boolean,
    default: false,
  },
  showLiveRtp: {
    type: Boolean,
    default: false,
  },
  showVolatility: {
    type: Boolean,
    default: false,
  },
});

const { $imgSrcResize, } = useNuxtApp();

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const locationStore = useLocationStore();

const {
  isGamingRestricted,
} = storeToRefs(locationStore);

const {
  handleOpenRegisterModal,
} = useUserAuth;

const locationRestricted = computed(() => useRuntimeConfig().public.RESTRICT_LOCATIONS && (props.game.locationRestricted || isGamingRestricted.value));
const landingLink = computed(() => {
  if (
    !isUserLogged.value
    || locationRestricted.value
  ) {
    return false;
  }

  let route = '/games';

  if (props.category === 'live-games-promoted') { route = '/live-games'; }

  if (props.game?.metadata?.minigame) { route = '/minigames'; }

  if (props.game?.slug === '/') { return route; }

  return `${route}/${props.game?.slug}/`;
});
const wrapper = computed(() => landingLink.value ? resolveComponent('NuxtLink') : 'div');
const imageUrl = computed(() => props.game?.thumbnail || null);
const gameName = computed(() => props.game?.name || '');
const tagsDisplay = computed(() => (props.tags || []).concat(props.game.metadata?.tags || []));

function cardAction() {
  if (!locationRestricted.value) {
    if (isUserLogged.value) {
      navigateTo(landingLink.value);
    } else {
      handleOpenRegisterModal();
    }
  }
}
</script>
