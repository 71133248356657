<template>
  <ul
    class="font-bold w-full animate-fade-in px-1 pb-1 border-t-[1px] border-t-slate-600 flex-1 mt-1 pt-1"
    :class="placement === 'page' ? 'text-base' : 'text-xs sm:text-sm'"
  >
    <li
      v-for="(winner, index) in winnersDisplay"
      :key="index"
      class="flex items-center justify-between w-full transition-opacity"
      :class="placement === 'page' ? 'border-b border-slate-500 last-of-type:border-none hover:bg-slate-500/30 px-2 py-1' : 'py-[1px]'"
    >
      <div class="shrink-1 overflow-hidden">
        <NuxtLink
          :to="`/${winner.displayName.toLowerCase()}`"
          class="flex items-center overflow-hidden mr-1 group/winner"
        >
          <UserAvatar
            :url="winner.avatarUrl"
            :country="winner.currentCountry ?? winner.country"
            :size="placement ? 'lg': 'xs'"
            class="transition-all group-hover/winner:border-gray-300"
            :class="placement === 'page' ? 'mr-1.5' : 'mr-1'"
          />
          <h3
            class="font-bold truncate leading-tight"
            :class="placement === 'page' ? 'text-sm' : 'text-2xs text-cyan'"
          >
            {{ winner.displayName }}
          </h3>
        </NuxtLink>
      </div>

      <div class="flex items-center text-white shrink-0">
        <CurrencyIconImage
          :currency="winner?.prize?.currencyCode"
          :class="placement === 'page' ? 'mr-0.5 h-3' : 'mr-0.5 h-2.5'"
        />
        <span
          class="font-bold"
          :class="placement === 'page' ? 'text-base' : 'text-2xs'"
        >
          {{ winner?.prize?.amount }}
        </span>
      </div>
    </li>
  </ul>
</template>

<script setup>
import BigNumber from 'bignumber.js';

const props = defineProps({
  winners: {
    type: Array,
    default: null,
  },
  placement: {
    type: String,
    default: null,
  },
});

const winnersDisplay = computed(() => {
  const winnersLocal = props.winners || [];
  // if any of the winners hasn't got the displayName value set then return empty array
  if (winnersLocal.some(winner => !winner.displayName)) {
    return [];
  }

  winnersLocal?.sort((a, b) => new BigNumber(b.prize?.amount).toNumber() - new BigNumber(a.prize?.amount).toNumber());
  return winnersLocal;
});
</script>
