<template>
  <article
    v-if="stateComp < 6"
    class="flex flex-col transition-all duration-200 relative w-full min-h-[198px] md:h-[328px] rounded-xl group/card cursor-pointer"
    :class="[
      forceLinkToCompetition ? 'cursor-pointer' : '',
      metadata?.placeholder?.externalLink ? 'cursor-pointer' : '',
    ]"
    :style="{ backgroundColor: metadata?.bannerBgColour || '#000000' }"
    @click.stop="cardAction($event)"
  >
    <CardRaffleLabelOvertime v-if="overtimeActive"/>

    <div class="absolute w-full h-full pointer-events-none">
      <div
        class="relative z-10 flex flex-col items-end h-full w-full max-w-[843px] mx-auto pt-2 pr-[6px]"
        :class="{
          '!top-4': overtimeActive,
          '!top-2': drawingStateUi,
          '!top-[13px]': showMultiPrizeComponents,
        }"
      >
        <CardRaffleLabelText
          v-if="metadata?.labelText || showMultiPrizeComponents"
          :label-text="metadata?.labelText || `${prizes.length} Prizes`"
          :label-text-color="metadata?.labelTextColor"
          :label-color="metadata?.labelColor"
          :label-color-shade="metadata?.labelColorShade"
          :info-modal-id="metadata?.infoModalId"
        />

        <CardRaffleLabelEnterState
          v-if="userEnterState"
          :user-enter-state="userEnterState"
          :state="stateComp"
        />

        <CardRaffleCountdownDraw
          v-if="stateComp === 4 && drawTime"
          :draw-time="drawTime"
          :compact="compact"
          :overtime="overtime"
          class="mb-2 mr-2"
        />

        <CardRaffleLabelInternal v-if="!isPublic" class="!bottom-4"/>
      </div>
    </div>

    <CardRaffleLabelRequirements
      :nft-requirement="nftRequirement"
      :password-required="passwordRequired"
      :user-tag-required="userTagRequired"
      :state="stateComp"
      :overtime-active="overtimeActive"
      :show-multi-prize-components="showMultiPrizeComponents"
      :user-enter-state="userEnterState"
      :metadata="metadata"
      :erc20-requirement="erc20Requirement"
      :uncx-staking-requirement="uncxStakingRequirement"
    />

    <figure class="w-full h-full lg:max-h-[328px] rounded-xl overflow-hidden relative z-0 group">
      <NuxtLink
        :to="landingLink"
        :target="metadata?.placeholder?.externalLink ? '_blank' : null"
        class="relative block h-full"
        @click.stop=""
      >
        <ImageLoader
          v-if="!showMultiPrizeComponents"
          :classes="[
            'object-cover lg:object-top h-full block relative z-10 overflow-hidden lg:max-h-[328px] mx-auto',
            drawingStateUi || overtimeActive ? 'p-1 rounded-2xl' : '',
            drawingStateUi ? 'rainbow' : '',
          ]"
          card-dark
          :image-url="bannerImageUrl"
          alt="Competition image"
          :width="843"
          :height="273"
          :loading-type="imageLoadingType"
          allow-video-on-hover
          :force-video-on-hover="forceVideoOnHover"
          :block-video-autoplay="blockVideoAutoplay"
          :force-video-autoplay="metadata?.forceVideoAutoplay"
        />
        <ImageCarousel
          v-else
          :prizes="prizesComp"
          class="!absolute top-0 left-0 w-full h-full"
          :padded="drawingStateUi || overtimeActive"
          card-dark
          :width="350"
          :height="350"
          allow-force-index
          :force-index="currentPrizeIndex"
          :force-direction="currentPrizeDirection"
          :carousel-interacted="prizeCarouselInteracted"
          allow-video-on-hover
          :force-video-on-hover="forceVideoOnHover"
          :block-video-autoplay="blockVideoAutoplay"
          :force-video-autoplay="metadata?.forceVideoAutoplay"
        />
      </NuxtLink>
    </figure>

    <div class="w-full h-full overflow-hidden absolute z-0 group flex flex-col items-center justify-center">
      <div class="w-4/5 h-full flex flex-col justify-end relative">
        <NuxtLink
          :to="landingLink"
          :target="metadata?.placeholder?.externalLink ? '_blank' : null"
          class="relative block w-full mx-auto"
          @click.stop=""
        >
          <img :src="textImageUrl" class="w-full lg:w-auto lg:h-full mx-auto">
        </NuxtLink>
        <CardRaffleCountdownEnterBig
          v-if="closeTime"
          :close-time="closeTime"
          :is-closed="isClosed"
          :compact="compact"
          force-countdown-display
          :overtime="overtime"
          class="xxs:top-[20%] xs:top-[25%] sm:top-[25%] md:top-[20%] lg:top-[20%] absolute self-center md:group-hover:scale-110 duration-300 transition-all"
        />
        <CardRaffleButtonWide
          :close-time="closeTime"
          :is-closed="isClosed"
          :is-drawing="drawingStateUi"
          :metadata="metadata"
          :overtime="overtime"
          :participants="participants"
          @click.stop="cardAction($event)"
        />
      </div>
    </div>
  </article>
</template>

<script setup>
import { inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/user';
import { useSweepstakeStore } from '@/store/sweepstake';
import { useUiStore } from '@/store/ui';
import { useSweepstakePrizes } from '@/utils/useSweepstakePrizes';

const props = defineProps({
  compact: {
    type: Boolean,
    default: false,
  },
  imageUrl: {
    type: String,
    default: null,
  },
  blockchain: {
    type: Object,
    default: null,
  },
  prizes: {
    type: Array,
    default: null,
  },
  entries: {
    type: Array,
    default: null,
  },
  state: {
    type: [String, Number,],
    default: null,
  },
  userEnterState: {
    type: String,
    default: null,
  },
  metadata: {
    type: Object,
    default: null,
  },
  id: {
    type: [String, Number,],
    default: null,
  },
  closeTime: {
    type: [String, Date,],
    default: null,
  },
  drawTime: {
    type: [String, Date,],
    default: null,
  },
  participants: {
    type: Number,
    default: null,
  },
  forceLinkToCompetition: {
    type: Boolean,
    default: null,
  },
  overtime: {
    type: Object,
    default: null,
  },
  public: {
    type: Boolean,
    default: false,
  },
  imageLoadingType: {
    type: String,
    default: null,
  },
  assets: {
    type: Array,
    default: null,
  },
  forceVideoOnHover: {
    type: Boolean,
    default: null,
  },
  blockVideoAutoplay: {
    type: Boolean,
    default: null,
  },
  presentationType: {
    type: String,
    default: null,
  },
  mechanism: {
    type: String,
    default: null,
  },
});

const router = useRouter();
const { $dayjs, } = useNuxtApp();

const uiStore = useUiStore();
const {
  secondsTicker,
} = storeToRefs(uiStore);

const userStore = useUserStore();
const {
  userData,
} = storeToRefs(userStore);

const sweepstakeStore = useSweepstakeStore();
const {
  passwordsPassed,
} = storeToRefs(sweepstakeStore);
const {
  checkRequirementsPass,
  checkERC20RequirementsPass,
  checkUNCXStakingPass,
} = sweepstakeStore;

const { handleOpenNewTab, } = useNavigation();

const mq = inject('mq');

const nftRequirement = reactive({
  exist: false,
  passes: true,
  items: [],
});

const erc20Requirement = reactive({
  exists: false,
  pass: true,
  imageUrl: '',
  contractAddress: '',
  network: '',
  name: '',
});

const uncxStakingRequirement = reactive({
  exists: false,
  pass: true,
  imageUrl: '',
  contractAddress: '',
  network: '',
  name: '',
});

const isClosed = ref(false);
const currentPrizeIndex = ref(0);
const currentPrizeDirection = ref('next');
const prizeCarouselInteracted = ref(false);

const prizesComp = computed(() => useSweepstakePrizes(props.prizes));
const showMultiPrizeComponents = computed(() => {
  return (
    prizesComp.value.length > 1
    && props.presentationType === 'Competition'
    && props.mechanism === 'Standard'
  );
});
const overtimeActive = computed(() => stateComp.value !== 3 ? false : props.overtime?.active);
const stateComp = computed(() => Number(props.state));
const passwordRequired = computed(() => {
  if (props.entries?.length) { return false; }
  if (props.metadata?.password) {
    const passwordPassed = passwordsPassed.value.find(
      o => o.id === props.id
    );
    return !passwordPassed?.passed;
  } else {
    return false;
  }
});
const userTagRequired = computed(() => {
  if (props.entries?.length) { return false; }

  let requiredTags = props.metadata?.tags;

  if (!requiredTags) { return false; }

  if (!Array.isArray(requiredTags)) { requiredTags = [requiredTags,]; }

  const userTags = userData.value?.tags ?? [];

  if (userTags.length === 0) { return true; }

  const matchType = props.metadata?.tagsMatchType ?? 'ANY';
  let passed = false;

  switch (matchType) {
    case 'ALL':
      passed = requiredTags.every(t => userTags.includes(t));
      break;

    default:
      passed = requiredTags.some(t => userTags.includes(t));
      break;
  }

  return !passed;
});
const isPublic = computed(() => props.public);
const landingLink = computed(() => {
  if (props.metadata?.placeholder?.externalLink) { return props.metadata.placeholder.externalLink; }

  if (props.metadata?.placeholder?.landingDisabled) { return null; }

  return `/competition/${props.id}`;
});
const drawingStateUi = computed(() => !![5, 6,].includes(stateComp.value));
const bannerImageUrl = computed(() => {
  const key = mq.mdPlus ? 'bannerAlt' : 'bannerAltMobile';
  const bannerItem = props.assets.find(item => item.key === key);
  return bannerItem.url;
});
const textImageUrl = computed(() => {
  const bannerItem = props.assets.find(item => item.key === 'text');
  return bannerItem?.url;
});

function cardAction(e) {
  if (e.target.classList.contains('js-block-card-action')) { return; }

  if (props.metadata?.placeholder?.externalLink) {
    handleOpenNewTab(props.metadata.placeholder.externalLink);
    return;
  }

  if (props.metadata?.placeholder?.modalDisabled) {
    return;
  }

  return router.push({ path: `/competition/${props.id}`, });
}

function init() {
  const contractMetadata = {
    ...props.blockchain?.contract?.metadata,
    ...props.metadata,
  };
  const requirements = props.blockchain?.entryRequirements || null;
  const requirementsNftCount = contractMetadata?.nftRestrictionCount || 1;
  if (requirements) {
    for (const requirement of requirements) {
      if (requirement.type === 'NFT') {
        nftRequirement.exist = true;
        nftRequirement.items.push(requirement);
      }
    }
    nftRequirement.passes = checkRequirementsPass(
      requirements,
      requirementsNftCount
    );
  }
  if (contractMetadata?.erc20Gated) {
    erc20Requirement.exists = true;
    erc20Requirement.network = props.blockchain?.network;
    erc20Requirement.contractAddress = contractMetadata?.erc20Address;
    erc20Requirement.name = contractMetadata?.erc20Name;
    erc20Requirement.imageUrl = contractMetadata?.erc20ImageUrl;
    erc20Requirement.minBalance = contractMetadata?.erc20MinBalance;
    erc20Requirement.marketplaceUrl = contractMetadata?.marketplaceUrl;
    erc20Requirement.pass = checkERC20RequirementsPass(contractMetadata?.erc20Address, contractMetadata?.erc20MinBalance);
  }

  if (contractMetadata?.uncxStakingGated) {
    uncxStakingRequirement.exists = true;
    uncxStakingRequirement.network = props.blockchain?.network;
    uncxStakingRequirement.contractAddress = contractMetadata?.uncxStakingAddress;
    uncxStakingRequirement.name = contractMetadata?.uncxStakingTokenName;
    uncxStakingRequirement.imageUrl = contractMetadata?.uncxStakingImageUrl;
    uncxStakingRequirement.minBalance = contractMetadata?.uncxStakingMinBalance;
    uncxStakingRequirement.pass = checkUNCXStakingPass(contractMetadata?.uncxStakingAddress, contractMetadata?.uncxStakingMinBalance);
  }

  checkIsClosed();
}

function checkIsClosed() {
  if (!props.closeTime) { return; }
  const now = $dayjs();
  const endDate = $dayjs(props.closeTime);
  const expiresIn = endDate.diff(now, 's');
  if (expiresIn <= 0) {
    isClosed.value = true;
  } else {
    isClosed.value = false;
  }
}

watch(
  () => secondsTicker.value,
  () => {
    checkIsClosed();
  }
);

onMounted(() => {
  init();
});
</script>
