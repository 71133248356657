<template>
  <video
    v-if="isActive"
    ref="LootboxRevealVideo"
    class="absolute z-20 transition-opacity duration-300 top-0 left-0 w-full h-full max-w-none"
    :class="{ 'opacity-0': !showVideo }"
    width="400"
    height="400"
    playsinline
    muted
    disableremoteplayback
    draggable="false"
    preload="metadata"
    @loadedmetadata="autoPlayVideo"
    @ended="showVideo = false"
  >
    <source :src="`${lootboxRevealVideoUrl}#t=0.1`" type="video/mp4">
  </video>
</template>

<script setup>
const props = defineProps({
  assets: {
    type: Array,
    default: null,
  },
});

const showVideo = ref(false);
const isActive = ref(true);
const LootboxRevealVideo = ref();

const lootboxRevealVideoUrl = computed(() => {
  const videoAsset = props.assets?.find(asset => asset?.key === 'reveal' && asset?.type === 'Video' && asset.url);
  return videoAsset ? videoAsset.url : null;
});

function autoPlayVideo() {
  if (!LootboxRevealVideo.value) { return; }
  LootboxRevealVideo.value.play();
  showVideo.value = true;
}
</script>
