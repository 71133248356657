<template>
  <div
    v-if="sweepstakesDisplay.length > 0 && ready"
    :id="containerId"
    class="relative"
  >
    <transition name="fade">
      <ButtonCarousel
        v-if="!isMobileDevice && showPrevArrow"
        direction="left"
        :disable-arrow-bg="disableArrowBg"
        class="-translate-x-2"
        @click="moveCarousel(false)"
      />
    </transition>

    <transition name="fade">
      <ButtonCarousel
        v-if="!isMobileDevice && showNextArrow"
        direction="right"
        :disable-arrow-bg="disableArrowBg"
        class="translate-x-2"
        @click="moveCarousel(true)"
      />
    </transition>

    <transition name="fade">
      <div v-if="true" class="absolute right-0 -top-6 lg:-top-7 flex flex-nowrap items-center">
        <div v-if="displaySwitchButton" class="flex justify-center mr-2 lg:mr-4">
          <ButtonButton
            theme="loadmore"
            size="xs"
            class="!p-0"
            @click.prevent.stop="toggleFold"
          >
            <transition name="slidedown-fade-fast" mode="out-in" class="flex items-center">
              <span v-if="folded" class="relative block"><i class="icon-ico-squares-four text-lg"/>Grid </span>
              <span v-else class="relative block"><i class="icon-ico-columns text-lg"/>Carousel</span>
            </transition>
          </ButtonButton>
        </div>

        <div
          class="transition-all md:hidden"
          :class="[
            isMobileDevice && folded && showNextCurtain ? 'w-14 animate-swipe' : 'w-0'
          ]"
        >
          <div
            class="bg-slate-600 rounded-lg flex items-center justify-center py-0.5 overflow-hidden"
          >
            <span class="text-sm relative">Swipe</span>
            <span class="icon-ico-carret-right text-xs"/>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade-fast" mode="out-in" @after-enter="onAfterEnter">
      <div
        v-if="folded"
        ref="Scroller"
        v-dragscroll="!isMobileDevice"
        class="flex flex-nowrap pt-2 hardware-accelerate relative overscroll-x-contain -mx-1 w-[calc(100%+0.5rem)]"
        :class="[
          isMobileDevice ? 'overflow-x-scroll scrollbar-mobile' : 'overflow-x-scroll scrollbar-hide',
          dragging ? '!cursor-grabbing' : 'snap-x'
        ]"
        @scroll="handleScroll"
        @resize="handleResize"
        @dragscrollstart="dragStart"
        @dragscrollend="dragEnd"
      >
        <component
          v-bind="item"
          :is="determineCardComponent(item?.presentationType, item?.category?.name)"
          v-for="(item, index) in sweepstakesDisplay"
          :key="index"
          :item="item"
          :show-price="!hidePrice"
          :exchange-rate-eth-usd="exchangeRateEthUsd"
          :force-link-to-competition="false"
          :card-size="cardSize"
          color-theme="dark"
          :show-rtp="showRtp"
          :show-live-rtp="showLiveRtp"
          :show-volatility="showVolatility"
          image-loading-type="eager"
          hide-ended-button
          :class="{'pointer-events-none' : dragging}"
          @card-action="cardAction(item)"
        />
      </div>

      <div v-else>
        <transition-group
          name="cardlist"
          tag="div"
          class="w-full flex flex-wrap pt-2"
        >
          <component
            :is="determineCardComponent(item?.presentationType, item?.category?.name)"
            v-for="(item, index) in sweepstakesDisplay"
            v-bind="item"
            :key="index"
            :item="item"
            :show-price="!hidePrice"
            :exchange-rate-eth-usd="exchangeRateEthUsd"
            :force-link-to-competition="false"
            :show-reminder="false"
            :card-size="cardSize"
            :show-rtp="showRtp"
            :show-live-rtp="showLiveRtp"
            :show-volatility="showVolatility"
            hide-ended-button
            color-theme="dark"
            @card-action="cardAction(item)"
          />
        </transition-group>
        <div v-if="canLoadMore" class="w-100 flex justify-center mt-5">
          <ButtonButton v-if="!loadingGames" @click="$emit('load-games')">Load more</ButtonButton>
          <Spinner v-else/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import uuid from 'uuid';
import { dragscroll } from 'vue-dragscroll';
import { useDeviceStore } from '@/store/device';

export default defineComponent({
  name: 'ListSweepstakeExpander',
  directives: {
    dragscroll,
  },
  props: {
    sweepstakesDisplay: {
      type: Array,
      default: null,
    },
    exchangeRateEthUsd: {
      type: Number,
      default: null,
    },
    hidePrice: {
      type: Boolean,
      default: false,
    },
    carouselInitialState: {
      type: Boolean,
      default: false,
    },
    cardSize: {
      type: String,
      default: null,
    },
    collectionPresentationType: {
      type: String,
      default: null,
    },
    forceHideSwitchButton: {
      type: Boolean,
      default: false,
    },
    disableArrowBg: {
      type: Boolean,
      default: false,
    },
    loadingGames: {
      type: Boolean,
      default: false,
    },
    canLoadMore: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    showRtp: {
      type: Boolean,
      default: false,
    },
    showLiveRtp: {
      type: Boolean,
      default: false,
    },
    showVolatility: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'card-action',
    'load-games',
  ],
  setup() {
    const CardRaffleMain = resolveComponent('CardRaffleMain');
    const CardRaffleLootbox = resolveComponent('CardRaffleLootbox');
    const CardGameMain = resolveComponent('CardGameMain');
    const CardRaffleWide = resolveComponent('CardRaffleWide');
    return { CardRaffleMain, CardRaffleLootbox, CardGameMain, CardRaffleWide, };
  },
  data() {
    return {
      scrollerPosition: 0,
      scrollerWidth: 0,
      scrollerContainerWidth: 0,
      showNextArrow: true,
      showNextCurtain: false,
      folded: false,
      containerId: 'container-' + uuid(),
      dragging: false,
      oldScrollerPosition: 0,
      ready: false,
    };
  },
  computed: {
    ...mapState(useDeviceStore, ['isMobileDevice',]),

    showPrevArrow() {
      if (!this.folded) { return false; }
      return this.scrollerPosition > 10;
    },

    displaySwitchButton() {
      if (this.forceHideSwitchButton) { return false; }
      return this.scrollerWidth > this.scrollerContainerWidth;
    },
  },
  watch: {
    folded(val) {
      if (!val) {
        this.showNextArrow = false;
      } else {
        this.handleResize();
      }
    },
  },
  created() {
    this.folded = this.carouselInitialState;
    this.ready = true;

    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      this.handleScroll(false);
    });
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleScroll(e) {
      if (e) {
        e.preventDefault();
        this.scrollerPosition = e.target.scrollLeft;
      } else {
        this.scrollerPosition = 0;
      }

      const offset = 10;
      const nextArrowCheck = this.scrollerWidth - this.scrollerPosition - this.scrollerContainerWidth;
      this.showNextArrow = nextArrowCheck > offset;

      this.showNextCurtain = nextArrowCheck > offset;
    },
    handleResize() {
      if (!this.folded) { return; }
      this.scrollerContainerWidth = this.$refs?.Scroller?.clientWidth;
      this.scrollerWidth = this.$refs.Scroller?.scrollWidth;
    },
    cardAction(item) {
      this.$emit('card-action', item);
    },
    moveCarousel(next) {
      if (!this.folded) { return; }

      this.handleResize();
      const distance = Math.round(this.scrollerWidth / this.sweepstakesDisplay.length) * 2;
      let pos = this.scrollerPosition;
      if (next) {
        pos = pos + distance;
      } else {
        pos = pos - distance;
      }
      this.dragging = false;
      this.scrollToPosition(pos);
    },
    scrollToPosition(pos) {
      if (!this.folded) { return; }

      this.$refs.Scroller.scrollTo({
        left: pos,
        behavior: 'smooth',
      });
    },
    toggleFold() {
      this.folded = !this.folded;
    },
    onAfterEnter() {
      if (this.folded) {
        this.handleResize();
        this.handleScroll(false);
      }
      if (this.isMobileDevice) {
        useScrollTo(`#${this.containerId}`, 100);
      }
    },
    dragStart() {
      this.oldScrollerPosition = this.scrollerPosition;
      this.dragging = true;
    },
    dragEnd() {
      const cardsCount = this.scrollerWidth / this.sweepstakesDisplay.length;
      const itemsFromLeft = Math.round(this.scrollerPosition / cardsCount);

      const cardWidth = Math.round(cardsCount * 10) / 10;
      let offset = itemsFromLeft * cardWidth;

      // Amend offset for situations when scroll was too subtle for css snap to trigger
      const diff = this.scrollerPosition - this.oldScrollerPosition;
      if (diff < cardWidth / 2) {
        offset += (cardWidth * Math.sign(diff));
      }

      this.scrollToPosition(offset);

      setTimeout(() => {
        this.dragging = false;
      }, 500);
    },
    determineCardComponent(presentationType, categoryName) {
      if (this.collectionPresentationType) { presentationType = this.collectionPresentationType; }

      if (presentationType === 'CasinoGame') {
        return this.CardGameMain;
      } else if (presentationType === 'Lootbox') {
        return this.CardRaffleLootbox;
      } else {
        if (
          categoryName === 'special-feature'
          && !this.wide
        ) {
          return this.CardRaffleWide;
        }

        return this.CardRaffleMain;
      }
    },
  },
});
</script>
