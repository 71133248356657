<template>
  <div class="mb-0">
    <div class="w-[45%] md:w-64 mx-auto overflow-hidden relative flex items-center justify-center rounded py-3 md:py-1.5 mb-2 xxs:py-2 cursor-pointer">
      <div
        class="relative z-10 text-center px-1 whitespace-nowrap leading-none flex flex-col"
        :class="btn.bgRainbow || metadata?.btnTheme === 'pure-gold' ? 'text-slate-800' : 'text-white'"
      >
        <span class="font-bold text-sm md:text-base mb-0.5">
          {{ btn.text }}
        </span>
      </div>

      <div
        class="absolute z-0 left-0 top-0 w-full h-full before:absolute transition-all"
        :class="[
          btn.bgRainbow ? 'bg-rainbow-pastel' : 'bg-blue-500 md:group-hover/card:bg-blue-400',
          metadata?.btnTheme === 'pure-gold' ? '!bg-pure-gold-gradient !bg-white md:group-hover/card:opacity-80' : '',
        ]"
      />
    </div>
    <span class="text-2xs md:text-xs font-bold text-white/75 text-center -mt-1 block mb-1">
      <div v-if="participants"> {{ participants }} Entered </div>
    </span>
  </div>
</template>

<script setup>
const { $dayjs, } = useNuxtApp();

const props = defineProps({
  closeTime: {
    type: String,
    default: null,
  },
  isClosed: {
    type: Boolean,
    default: false,
  },
  metadata: {
    type: Object,
    default: null,
  },
  overtime: {
    type: Object,
    default: null,
  },
  participants: {
    type: Number,
    default: null,
  },
  isDrawing: {
    type: Boolean,
    default: false,
  },
});

const btn = computed(() => {
  if (props.metadata?.placeholder?.cardButtonLabel) {
    return { text: props.metadata?.placeholder?.cardButtonLabel, bgRainbow: false, };
  }

  const cardEnterButtonLabel = props.metadata?.cardEnterButtonLabel || 'Enter For Free';

  if (!props.closeTime) {
    return { text: cardEnterButtonLabel, bgRainbow: false, };
  }

  if (props.isDrawing) {
    return {
      text: 'Drawing...',
      bgRainbow: true,
    };
  }
  if (props.isClosed) {
    return { text: 'Drawing soon', bgRainbow: true, };
  }

  const now = $dayjs();
  const endDate = $dayjs(props.closeTime);
  const expiresIn = endDate.diff(now, 's');

  if (expiresIn <= 0) {
    return { text: 'Drawing soon', bgRainbow: true, };
  }

  return { text: cardEnterButtonLabel, bgRainbow: false, };
});
</script>
