<template>
  <article
    class="gtm-card-competition flex flex-col rounded-xl transition-all duration-200 relative group/card"
    :class="[
      forceLinkToCompetition ? 'cursor-pointer' : '',
      colorTheme === 'dark' ? 'bg-slate-900' : 'bg-white',
      metadata?.placeholder?.externalLink ? 'cursor-pointer' : '',
      cardSize ? cardSize : 'card-medium'
    ]"
    @click.stop="cardAction()"
  >
    <CardRaffleLabelOvertime v-if="overtimeActive"/>

    <!-- Top right corner badges -->
    <div
      class="absolute z-10 top-[6px] right-[6px] flex flex-col items-end"
      :class="{'!top-4': overtimeActive, '!top-2': drawingStateUi}"
    >
      <CardRaffleLabelText
        v-if="metadata?.labelText"
        :label-text="metadata.labelText"
        :label-text-color="metadata?.labelTextColor"
        :label-color="metadata?.labelColor"
        :label-color-shade="metadata?.labelColorShade"
        :info-modal-id="metadata?.infoModalId"
      />

      <CardRaffleLabelEnterState
        v-if="userEnterState"
        :user-enter-state="userEnterState"
        :state="stateComp"
      />
    </div>

    <!-- Top left corner badges -->
    <CardRaffleLabelRequirements
      :nft-requirement="nftRequirement"
      :password-required="passwordRequired"
      :user-tag-required="userTagRequired"
      :state="stateComp"
      :overtime-active="overtimeActive"
      :user-enter-state="userEnterState"
      :metadata="metadata"
      :erc20-requirement="erc20Requirement"
      :uncx-staking-requirement="uncxStakingRequirement"
    />

    <figure class="w-full aspect-square rounded-xl overflow-hidden relative z-0 group -top-[0.5px] border-2 border-transparent">
      <NuxtLink
        :to="landingLink"
        :target="metadata?.placeholder?.externalLink ? '_blank' : null"
        @click.stop=""
      >
        <CardRaffleLootboxRevealAnim
          v-if="displayLootboxRevealAnim"
          :assets="assets"
          :state="stateComp"
        />

        <ImageLoader
          :key="videoUrl"
          class="lg:group-hover/card:scale-[1.08] transition-transform duration-300"
          :classes="[
            'object-cover h-full w-full block relative z-10',
            drawingStateUi || overtimeActive ? 'p-1 rounded-2xl' : ''
          ]"
          :image-url="imageUrl"
          :animation-url="videoUrl"
          alt="Competition image"
          :width="350"
          :height="350"
          :loading-type="imageLoadingType"
          allow-video-on-hover
          :force-video-on-hover="forceVideoOnHover"
          :block-video-autoplay="blockVideoAutoplay"
          :force-video-autoplay="metadata?.forceVideoAutoplay"
        />

        <CardRaffleBorderAnimation
          v-if="drawingStateUi || overtimeActive"
          :drawing-state-ui="drawingStateUi"
          :overtime-active="overtimeActive"
          :color-theme="colorTheme"
        />

        <CardRaffleCountdownEnter
          v-if="stateComp === 3 && closeTime"
          :close-time="closeTime"
          :is-closed="isClosed"
          :compact="compact"
          force-countdown-display
          :overtime="overtime"
          sweepstake-type="lootbox"
        />

        <CardRaffleCountdownDraw
          v-if="stateComp === 4 && drawTime"
          :draw-time="drawTime"
          :compact="compact"
          :overtime="overtime"
        />

        <CardRaffleLabelInternal v-if="!isPublic"/>

        <CardRaffleLabelMore v-if="landingLink" :color-theme="colorTheme">
          More Info
        </CardRaffleLabelMore>
      </NuxtLink>
    </figure>

    <div class="pt-2 overflow-hidden flex flex-col justify-between flex-grow">
      <div class="flex flex-col">
        <div
          class="grow overflow-hidden relative after:h-full after:w-4 after:right-0 after:top-0 after:absolute after:bg-gradient-to-r"
          :class="colorTheme === 'dark' ? 'after:from-slate-600/0 after:to-slate-900/100' : 'after:from-white/0 after:to-white/100'"
        >
          <CardRaffleTextName
            :color-theme="colorTheme"
            :sweepstake-name="name"
          />

          <CardRaffleValueDisplay
            :total-funds="totalFunds"
            :currency-code="currencyCode"
            :color-theme="colorTheme"
            :compact="compact"
            animate-value-update
          />
        </div>
      </div>

      <CardRaffleLootboxWinners
        v-if="winners?.length && [6,8].includes(stateComp)"
        :winners="winners"
      />

      <CardRaffleFooterCompetition
        v-if="!(stateComp == 8 && presentationType === 'Lootbox')"
        :color-theme="colorTheme"
        :metadata="metadata"
        :close-condition="closeCondition"
        :entry-estimate="entryEstimate"
        :winners="winners"
        :estimated-entry-count="estimatedEntryCount"
        :state="stateComp"
      />

      <CardRaffleWaitingBar
        v-if="stateComp === 2"
        :open-time="openTime"
      />

      <CardRaffleButtonClosed v-if="showClosedButton" :state="stateComp" :is-full="isFull"/>

      <CardRaffleButtonEnter
        v-if="stateComp === 3"
        :close-time="closeTime"
        :is-closed="isClosed"
        :metadata="metadata"
        :overtime="overtime"
        :participants="participants"
        :class="metadata?.placeholder?.modalDisabled ? '': 'cursor-pointer'"
        @click.stop="cardAction()"
      />
    </div>
  </article>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/user';
import { useSweepstakeStore } from '@/store/sweepstake';
import { useUiStore } from '@/store/ui';

const props = defineProps({
  colorTheme: {
    type: String,
    default: null,
  },
  compact: {
    type: Boolean,
    default: false,
  },
  imageUrl: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  blockchain: {
    type: Object,
    default: null,
  },
  entryEstimate: {
    type: Object,
    default: null,
  },
  closeCondition: {
    type: Object,
    default: null,
  },
  entries: {
    type: Object,
    default: null,
  },
  state: {
    type: [String, Number,],
    default: null,
  },
  userEnterState: {
    type: String,
    default: null,
  },
  winners: {
    type: Array,
    default: null,
  },
  metadata: {
    type: Object,
    default: null,
  },
  id: {
    type: [String, Number,],
    default: null,
  },
  closeTime: {
    type: [String, Date,],
    default: null,
  },
  openTime: {
    type: [String, Date,],
    default: null,
  },
  drawTime: {
    type: [String, Date,],
    default: null,
  },
  entryCount: {
    type: Number,
    default: null,
  },
  participants: {
    type: Number,
    default: null,
  },
  forceLinkToCompetition: {
    type: Boolean,
    default: false,
  },
  overtime: {
    type: Object,
    default: null,
  },
  public: {
    type: Boolean,
    default: false,
  },
  imageLoadingType: {
    type: String,
    default: null,
  },
  assets: {
    type: Array,
    default: null,
  },
  totalFunds: {
    type: String,
    default: null,
  },
  currencyCode: {
    type: String,
    default: null,
  },
  reminder: {
    type: Boolean,
    default: false,
  },
  hideEndedButton: {
    type: Boolean,
    default: false,
  },
  presentationType: {
    type: String,
    default: null,
  },
  blockVideoAutoplay: {
    type: Boolean,
    default: false,
  },
  forceVideoOnHover: {
    type: Boolean,
    default: false,
  },
  cardSize: {
    type: String,
    default: null,
  },
});

const emits = defineEmits([
  'card-action',
]);

const router = useRouter();
const { $dayjs, } = useNuxtApp();

const uiStore = useUiStore();
const {
  secondsTicker,
} = storeToRefs(uiStore);

const sweepstakeStore = useSweepstakeStore();
const {
  passwordsPassed,
} = storeToRefs(sweepstakeStore);
const {
  checkRequirementsPass,
  checkERC20RequirementsPass,
  checkUNCXStakingPass,
} = sweepstakeStore;

const userStore = useUserStore();
const {
  userData,
} = storeToRefs(userStore);

const { handleOpenNewTab, } = useNavigation();

const nftRequirement = reactive({
  exist: false,
  passes: true,
  items: [],
});

const erc20Requirement = reactive({
  exists: false,
  pass: true,
  imageUrl: '',
  contractAddress: '',
  network: '',
  name: '',
});

const uncxStakingRequirement = reactive({
  exists: false,
  pass: true,
  imageUrl: '',
  contractAddress: '',
  network: '',
  name: '',
});

const isClosed = ref(false);
const hasReminder = ref(null);
const allStatesSeen = ref([]);

const stateComp = computed(() => Number(props.state));
const overtimeActive = computed(() => stateComp.value !== 3 ? false : props.overtime?.active);
const estimatedEntryCount = computed(() => {
  if (props.metadata?.useEntryCount || !props.entryEstimate?.max) { return props.entryCount; }

  return props.entryEstimate.max - props.entryEstimate.remaining;
});
const passwordRequired = computed(() => {
  if (props.entries?.length) { return false; }
  if (props.metadata?.password) {
    const passwordPassed = passwordsPassed.value.find(o => o.id === props.id);
    return !passwordPassed?.passed;
  } else {
    return false;
  }
});
const isFull = computed(() => props.closeCondition?.remaining <= 0);
const userTagRequired = computed(() => {
  if (props.entries?.length) { return false; }

  let requiredTags = props.metadata?.tags;

  if (!requiredTags) { return false; }

  if (!Array.isArray(requiredTags)) { requiredTags = [requiredTags,]; }

  const userTags = userData.value?.tags ?? [];

  if (userTags.length === 0) { return true; }

  const matchType = props.metadata?.tagsMatchType ?? 'ANY';
  let passed = false;

  switch (matchType) {
    case 'ALL':
      passed = requiredTags.every(t => userTags.includes(t));
      break;

    default:
      passed = requiredTags.some(t => userTags.includes(t));
      break;
  }

  return !passed;
});
const isPublic = computed(() => props.public);
const landingLink = computed(() => {
  if (props.metadata?.placeholder?.externalLink) { return props.metadata.placeholder.externalLink; }

  if (props.metadata?.placeholder?.landingDisabled) { return null; }

  return `/competition/${props.id}`;
});
const videoUrl = computed(() => {
  if (stateComp.value !== 8) { return null; }

  let videoKey = 'closed';
  if (stateComp.value === 8) {
    videoKey = 'open';
  }
  const videoAsset = props.assets.find(asset => asset?.key === videoKey && asset?.type === 'Video' && asset.url);
  return videoAsset ? videoAsset.url : null;
});
const drawingStateUi = computed(() => !![5,].includes(stateComp.value));
const displayLootboxRevealAnim = computed(() => props.presentationType === 'Lootbox' && stateComp.value === 8 && allStatesSeen.value.length > 1);
const showClosedButton = computed(() => {
  if ((props.hideEndedButton && stateComp.value === 8) || [1, 2, 3, 6, 8,].includes(stateComp.value)) { return false; }
  return true;
});

init();

function cardAction() {
  if (props.metadata?.placeholder?.externalLink) {
    handleOpenNewTab(props.metadata.placeholder.externalLink);
    return;
  }

  if (props.metadata?.placeholder?.modalDisabled) {
    return;
  }

  if (props.forceLinkToCompetition) {
    return router.push({ path: `/competition/${props.id}`, });
  }

  if ([3,].includes(stateComp.value) && (!isClosed.value || (isClosed.value && props.overtime?.applicable === true))) {
    emits('card-action');
  }
}

function init() {
  const contractMetadata = {
    ...props.blockchain?.contract?.metadata,
    ...props.metadata,
  };
  const requirements = props.blockchain?.entryRequirements || null;
  const requirementsNftCount = contractMetadata?.nftRestrictionCount || 1;
  if (requirements) {
    for (const requirement of requirements) {
      if (requirement.type === 'NFT') {
        nftRequirement.exist = true;
        nftRequirement.items.push(requirement);
      }
    }
    nftRequirement.passes = checkRequirementsPass(requirements, requirementsNftCount);
  }
  if (contractMetadata?.erc20Gated) {
    erc20Requirement.exists = true;
    erc20Requirement.network = props.blockchain?.network;
    erc20Requirement.contractAddress = contractMetadata?.erc20Address;
    erc20Requirement.name = contractMetadata?.erc20Name;
    erc20Requirement.imageUrl = contractMetadata?.erc20ImageUrl;
    erc20Requirement.minBalance = contractMetadata?.erc20MinBalance;
    erc20Requirement.marketplaceUrl = contractMetadata?.marketplaceUrl;
    erc20Requirement.pass = checkERC20RequirementsPass(contractMetadata?.erc20Address, contractMetadata?.erc20MinBalance);
  }

  if (contractMetadata?.uncxStakingGated) {
    uncxStakingRequirement.exists = true;
    uncxStakingRequirement.network = props.blockchain?.network;
    uncxStakingRequirement.contractAddress = contractMetadata?.uncxStakingAddress;
    uncxStakingRequirement.name = contractMetadata?.uncxStakingTokenName;
    uncxStakingRequirement.imageUrl = contractMetadata?.uncxStakingImageUrl;
    uncxStakingRequirement.minBalance = contractMetadata?.uncxStakingMinBalance;
    uncxStakingRequirement.pass = checkUNCXStakingPass(contractMetadata?.uncxStakingAddress, contractMetadata?.uncxStakingMinBalance);
  }

  checkIsClosed();
  hasReminder.value = props.reminder;
  allStatesSeen.value.push(stateComp.value);
}

function checkIsClosed() {
  if (!props.closeTime) { return; }
  const now = $dayjs();
  const endDate = $dayjs(props.closeTime);
  const expiresIn = endDate.diff(now, 's');
  if (expiresIn <= 0) {
    isClosed.value = true;
  } else {
    isClosed.value = false;
  }
}

watch(
  () => secondsTicker.value,
  () => {
    checkIsClosed();
  }
);

watch(
  () => stateComp.value,
  (val) => {
    allStatesSeen.value.push(val);
  }
);
</script>
